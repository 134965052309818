import React from "react"
import {Helmet} from "react-helmet"
import Layout from "../components/Layout"
import SubHeader from "../components/SubHeader"
import whoright from "../images/who1.svg"
import wholeft from "../images/who2.svg"
import '../styles/global.css'

export default function About() {
  return (
    <Layout>
        <Helmet
            htmlAttributes={{ lang:"en" }}
            title="PlanetFx - About Us"
            meta={[{name:"viewport", content:"width=device-width, initial-scale=1.0"}, {name: "description", content: "Planet Fx adalah studio kreatif pembuatan Company Profile, Iklan, Explainer, ILM, Teaser, Bumper berupa Animasi Flat Motion Graphic dan juga pembuatan Media Interaktif dan Design"}]}>
        </Helmet>
        <SubHeader title="TENTANG KAMI"/>
        <main>
        <section className="layout">
            <article className="who-content">
                <aside className="who-contents">
                    <h3>Apa itu Planetfx?</h3>
                    <p>Yuk kenalan dengan Planetfx</p>
                    <hr/>
                    <p>Planetfx adalah studio kreatif pembuatan motion graphic animation, videography & design graphic di Indonesia yang berisi jiwa-jiwa muda yang energik, dinamis, dan kreatif. Mulai 2015, kami berkarya di bidang dunia kreatif, yang memfokuskan diri ke pembuatan video motion graphic animation lalu di tahun 2020 kami berkembang ke pembuatan videography & design graphic dengan brand Planetfx.</p>
                </aside>
                <figure className="who-order">
                    <img src={whoright} alt="who we are"/>
                </figure>
            </article>
            <article className="who-content">
                <figure>
                    <img src={wholeft} alt="who we are"/>
                </figure>
                <aside className="who-contents">
                    <p>Kami menghadirkan bermacam variasi produk yang dapat disesuaikan dengan kebutuhan Anda. Materi dan informasi mulai dari kementerian, pemerintahan, universitas, rumah sakit, lembaga amil, digital startup, butik, kuliner, otomotif, dan bidang lainnya dapat diubah menjadi produk kreatif kami. 
                    <br/>
                    Kami percaya dengan semakin lengkapnya model materi yang Anda miliki, maka kegiatan sosialisasi, company profile, hingga promosi usaha semakin tampak profesional dan menarik bagi calon klien. Tim Planetfx siap mengubah materi Anda dengan mengikuti tren konsep design yang sedang populer. </p>
                </aside>
            </article>
        </section>
    </main>
    </Layout>
  )
}